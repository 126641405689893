import React from "react";
import styled from "styled-components";
import background from "../../assets/images/particle-bg.jpg";
import StoreButton from "../../components/StoreButton/StoreButton";
import AppImage from "../../components/AppImage/AppImage";
import { TextContainer, Description, PageWrapper } from "../Styles/Page.styled";
import { Link } from "react-router-dom";

const PrivacyPolicyButton = styled(Link)`
  background: none;
  color: blue;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  color: #193f84;
  font-size: 0.8em;

  &:hover {
    color: #2953a1;
  }

  &:focus {
    outline: none;
  }
`;

function DigitalOcean() {
  return (
    <PageWrapper>
      <AppImage
        src={require("../../assets/images/status-screenshot.png")}
        alt="Status for DigitalOcean App"
      />
      <TextContainer>
        <h1>Status for DigitalOcean</h1>
        <Description>
          Get real-time updates on DigitalOcean's service status with our app.
          Monitor services, access widgets for quick checks, view past
          incidents, and stay informed about upcoming maintenances. Whether
          you're a developer, sysadmin, or business owner, stay informed to
          ensure smooth operations for your projects and services.
        </Description>
        <StoreButton type="AppStore" url="" />
        <div style={{ height: "16px" }}></div>
        <PrivacyPolicyButton to="/status-for-digitalocean/privacy-policy">
          Privacy Policy
        </PrivacyPolicyButton>
      </TextContainer>
    </PageWrapper>
  );
}

export default DigitalOcean;
